import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { PortfolioCopiersTable } from '@features/portfolio/portfolio-copiers';
import { usePortfolioOutletContext } from '@entities/portfolio';
import { ContainerRow } from '@src/components/styled';
import { BidToQueue } from '@features/portfolio/portfolio-copy';
const Container = styled(ContainerRow)(props => ({
    height: 'auto',
    alignItems: 'start',
    justifyContent: 'space-between',
    gap: props.theme.spacing_sizes.l,
}));
const PortfolioCopiers = () => {
    const { portfolioId } = usePortfolioOutletContext();
    return (_jsxs(Container, { children: [_jsx(BidToQueue, { portfolioId: String(portfolioId) }), _jsx(PortfolioCopiersTable, { portfolioId: String(portfolioId) })] }));
};
export default PortfolioCopiers;
