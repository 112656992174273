import { jsx as _jsx } from "react/jsx-runtime";
import { Trans } from 'react-i18next';
import { matchPathPartial } from '@app/routing/helpers';
import { RouteNames } from '@app/routing/types';
const GeneralPortfolioInformationTitle = ({ language }) => Trans({
    ns: 'common',
    i18nKey: 'pages.portfolios.general_portfolio_information.title',
    lang: language,
});
const CurrentPositionsTitle = ({ language }) => Trans({
    ns: 'common',
    i18nKey: 'pages.portfolios.current_positions',
    lang: language,
});
const PositionsHistoryTitle = ({ language }) => Trans({
    ns: 'common',
    i18nKey: 'pages.portfolios.history',
    lang: language,
});
const CopiersTitle = ({ language }) => Trans({
    ns: 'common',
    i18nKey: 'pages.portfolios.copiers',
    lang: language,
});
export const getPortfolioTabs = (language) => [
    {
        value: 'information',
        label: _jsx(GeneralPortfolioInformationTitle, { language: language }),
    },
    {
        value: 'current_positions',
        label: _jsx(CurrentPositionsTitle, { language: language }),
    },
    {
        value: 'history',
        label: _jsx(PositionsHistoryTitle, { language: language }),
    },
    {
        value: 'copiers',
        label: _jsx(CopiersTitle, { language: language }),
    },
];
export const matchPathToPortfolioTab = (pathname) => {
    let tab;
    if (matchPathPartial(RouteNames.MY_PROFILE_PORTFOLIOS_PORTFOLIO_INFORMATION, pathname) ||
        matchPathPartial(RouteNames.PROFILE_PORTFOLIOS_PORTFOLIO_INFORMATION, pathname)) {
        tab = 'information';
    }
    else if (matchPathPartial(RouteNames.MY_PROFILE_PORTFOLIOS_PORTFOLIO_CURRENT_POSITIONS, pathname) ||
        matchPathPartial(RouteNames.PROFILE_PORTFOLIOS_PORTFOLIO_CURRENT_POSITIONS, pathname)) {
        tab = 'current_positions';
    }
    else if (matchPathPartial(RouteNames.MY_PROFILE_PORTFOLIOS_PORTFOLIO_HISTORY, pathname) ||
        matchPathPartial(RouteNames.PROFILE_PORTFOLIOS_PORTFOLIO_HISTORY, pathname)) {
        tab = 'history';
    }
    else if (matchPathPartial(RouteNames.MY_PROFILE_PORTFOLIOS_PORTFOLIO_COPIERS, pathname) ||
        matchPathPartial(RouteNames.PROFILE_PORTFOLIOS_PORTFOLIO_COPIERS, pathname)) {
        tab = 'copiers';
    }
    return tab;
};
